<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.classroom.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.classroom.list.title"
        ></app-i18n>
      </h1>

      <app-classroom-list-toolbar></app-classroom-list-toolbar>
      <app-classroom-list-filter></app-classroom-list-filter>
      <app-classroom-list-table></app-classroom-list-table>
    </div>
  </div>
</template>

<script>
import ClassroomListFilter from '@/modules/classroom/components/classroom-list-filter.vue';
import ClassroomListTable from '@/modules/classroom/components/classroom-list-table.vue';
import ClassroomListToolbar from '@/modules/classroom/components/classroom-list-toolbar.vue';

export default {
  name: 'app-classroom-list-page',

  components: {
    [ClassroomListFilter.name]: ClassroomListFilter,
    [ClassroomListTable.name]: ClassroomListTable,
    [ClassroomListToolbar.name]: ClassroomListToolbar,
  },
};
</script>

<style></style>
